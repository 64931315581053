/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "images/Screenshot_2016-12-30-14-16-19-989_cz.destil.settleup-350x622.png",
    alt: ""
  }), "Antes que nada tengo que decir que no me llevo comisión por anunciar este software pero que si sus creadores quieren recompensarme… ¡Les paso mi número de cuenta! :D"), "\n", React.createElement(_components.p, null, "La idea es dar una descripción somera de este software que me ha parecido muy útil tanto por sus ideas como por su forma de presentarlas. Además intenta continuar una conversación formada sobre software de contabilidad orientado a comunidades que empezaba ", React.createElement(_components.a, {
    href: "https://lasindias.blog/un-estado-de-cuentas-de-la-cooperativa-para-compartir"
  }, "aquí"), "."), "\n", React.createElement(_components.p, null, "Settle Up permite, gestionar las cuentas de un grupo sin tener un fondo común, o por decirlo de alguna forma este fondo común es virtual y la aplicación está al tanto en todo momento de las deudas entre los integrantes del grupo. Me parece la aplicación definitiva para viajes con los amigos, pero también puede ser útil en cenas y cualquier tipo de evento social pues digamos que te hace olvidarte de aclarar cuentas, siempre están claras. El funcionamiento de la aplicación es este, tres pantallas; pagos, deudas y resumen:"), "\n", React.createElement(_components.p, null, "Pagos: Un feed con los pagos y transacciones del grupo, siempre me acuerdo del ", React.createElement(_components.a, {
    href: "https://lasindias.blog/todo-es-un-feed"
  }, "«Todo es un feed» de Manuel"), "."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "images/Screenshot_2016-12-30-14-16-29-163_cz.destil.settleup-350x622.png",
    alt: ""
  }), " Deudas: Muestra cuanto debe una persona a otra y trae una opción activada por defecto que te minimiza las deudas para hacer el mínimo número de transacciones. ", React.createElement(_components.img, {
    src: "images/Screenshot_2016-12-30-14-16-38-294_cz.destil.settleup-350x622.png",
    alt: ""
  }), " Resumen: Lo que ha pagado, gastado y recibido cada persona y su cuenta total. Además muestra el total gastado, por lo que se puede al final del viaje por ejemplo saber cuanto nos hemos gastado en él. Además indica quien es el siguiente en pagar, la persona que más deudas tiene para equilibrar un poco la balanza."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
